import React, { useEffect, useState, useRef } from 'react';
import './Home.css';
//import Schedule from './Schedule'

//import { Amplify, API, graphqlOperation } from 'aws-amplify'
import { Amplify, API } from 'aws-amplify'
//import { listPlayers } from './graphql/queries'
import { listTeams } from './graphql/queries'
import { getPlayer } from './graphql/queries'
import { playerByTierAndRank } from './graphql/queries'
import { teamPlayersByTeamId } from './graphql/queries'

import { createTeam } from './graphql/mutations'
import { createTeamPlayers } from './graphql/mutations'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const Home = () => {
    const [tier1Players, setTier1Players] = useState([])
    const [tier2Players, setTier2Players] = useState([])
    const [tier3Players, setTier3Players] = useState([])
    //const [playerList, setPlayerList] = useState([])
    //const [players, setPlayers] = useState({})
    //const players = {}
    const [formState, setFormState] = useState({ name: '', ownerName: '', email: '', player1: '', player2: '', player3: '', player4: '', player5: ''})
    const [teams, setTeams] = useState([])
    const teamRef = useRef('')
    const [isTeamFormShown, setIsTeamFormShown] = useState("a");
    const [teamPlayers, setTeamPlayers] = useState([])
    const [teamPlayer, setTeamPlayer] = useState([])
    const [teamName, setTeamName] = useState("")
    const [teamTotal, setTeamTotal] = useState(0)

    useEffect(() => {
        const fetchPlayers = async () => {
/*            const tier1 = {
                filter: {
                    tier: {
                        eq: 1
                    }
                }
            }
           const tier2 = {
                filter: {
                    tier: {
                        eq: 2
                    }
                }
            }
            const tier3 = {
                filter: {
                    tier: {
                        eq: 3
                    }
                }
            }
            
            const tier1PlayerData = await API.graphql({query: listPlayers, variables: tier1              
            })
            const tier2PlayerData = await API.graphql({query: listPlayers, variables: tier2              
            })
            const tier3PlayerData = await API.graphql({query: listPlayers, variables: tier3              
            })*/
            
            const tier1PlayerData = await API.graphql({query: playerByTierAndRank, variables: {tier: 1}              
            })
            //console.log(tier1PlayerData)
            const tier2PlayerData = await API.graphql({query: playerByTierAndRank, variables: {tier: 2}             
            })
            //console.log(tier2PlayerData)
            const tier3PlayerData = await API.graphql({query: playerByTierAndRank, variables: {tier: 3}              
            })

            //const playersData = await API.graphql({query: listPlayers })

            //console.log(playersData)
            const tier1Players = tier1PlayerData.data.PlayerByTierAndRank.items
            setTier1Players(tier1Players)
            const tier2Players = tier2PlayerData.data.PlayerByTierAndRank.items
            setTier2Players(tier2Players)
            const tier3Players = tier3PlayerData.data.PlayerByTierAndRank.items
            setTier3Players(tier3Players)
            //console.log(tier3Players)

            /*const playersList = playersData.data.listPlayers.items
            console.log(playersList)
            playersList.map((player) => {
                //console.log({player})
                players = { ...players, [player.id]: player.name }
                console.log(players)
            })*/

            //setPlayers(players)
            //console.log(players)
            formState.player1 = tier1Players[0].id

            formState.player2 = tier2Players[0].id
            formState.player3 = tier3Players[0].id
            formState.player4 = tier3Players[1].id
            formState.player5 = tier3Players[2].id
            //console.log(formState)
            //console.log(playerList)
        }
        fetchPlayers()
        fetchTeams()
        //console.log(teams)
    }, [])

    const setInput = (key, value, isNumber = false) => {
        value = (isNumber) ? parseInt(value) : value;
        setFormState({ ...formState, [key]: value })
    }

    const fetchTeams = async () => {
        //const teamData = await API.graphql(graphqlOperation(listTeams))
        const teamData = await API.graphql({query: listTeams, variables: {limit: 120}})
        const teams = teamData.data.listTeams.items
        setTeams(teams)
        //console.log(teams)

        teams.map((team) => {
            team.teamTotal = 0
            //console.log(team.players.items)
            team.players.items.map((teamPlayer) => {
                //console.log(teamPlayer.player)
                const playerResults = teamPlayer.player.results.items
                playerResults.map((result) => {
                    //console.log(result.earnings)
                    team.teamTotal = team.teamTotal + result.earnings
                    //console.log(teamPlayers)
                })     
            })
                
        })

    }

    const fetchPlayer = async (playerId) => {
        console.log('test')
        const playerData = await API.graphql({
            query: getPlayer,
            variables: {id: playerId }
        })
        const player = playerData.data.getPlayer
        console.log(player)
    }

    const newFunction = () => {
        //console.log(p1Name)
        //console.log(formState)
        //alert(formState.player1)
        //console.log(playerList)
        createNewTeam()
        //setIsTeamFormShown(current => !current)

        console.log('waited 5 seconds')
        setTimeout(() => {
            console.log('trying to run createNewPlayerTeam')
            
            createNewPlayerTeam(formState.player1)
            createNewPlayerTeam(formState.player2)
            createNewPlayerTeam(formState.player3)
            createNewPlayerTeam(formState.player4)
            createNewPlayerTeam(formState.player5)
            fetchTeams()
            formState.name = ''
            formState.ownerName = ''
            formState.email = ''
            formState.player1 = tier1Players[0].id

            formState.player2 = tier2Players[0].id
            formState.player3 = tier3Players[0].id
            formState.player4 = tier3Players[1].id
            formState.player5 = tier3Players[2].id
            if(teamRef.current) {
                alert('Your team has been added!')
                teamRef.current = ''
            } 
            
        }, 5000)  
        //const pl1 = 'brian'
        //alert(pl1)
        //fetchTeams()  
        //clearTimeout (timeout) - not sure if we need this  
   }

   const openCreateTeam = async () => {
        setIsTeamFormShown(current => "b")
        //fetchPlayer('425fa63d-e85f-49d5-b000-195127e9d20b')

   }

   const openStandings = async () => {
        setIsTeamFormShown(current => "a")
        setTeamName(current => "")

   }

    const openSchedule = async () => {
        //console.log('openSchedule')
        setIsTeamFormShown(current => "schedule")

    }

   const createNewTeam = async () => {
        if (!formState.name || !formState.ownerName || !formState.email || !formState.player1 || !formState.player2 || !formState.player3 || !formState.player4 || !formState.player5 ) {
                alert('You are missing some information in the form.  Please try again.')
        } else {
            const newTeam = await API.graphql({
                query: createTeam,
                variables: {input: { name: formState.name, ownerName: formState.ownerName, email: formState.email } }
            })
            teamRef.current = newTeam.data.createTeam.id
            //console.log(teamRef)
            const response = 'Click Ok and please wait while your team is being created'
            alert(response)
            setIsTeamFormShown(current => "a")
            //console.log(response)  
        }            
    }
        
    const createNewPlayerTeam = async (playerId) => {
        //console.log(teamRef)
        //const playerId = formState.player
        //console.log('teamId = ' + teamRef.current)
        //console.log('playerId = ' + playerId)
        //try {
            const newTeamPlayer = await API.graphql({
                query: createTeamPlayers,
                variables: {input: { teamId: teamRef.current, playerId: playerId } }
            })
            //console.log(newTeamPlayer)
        //} catch (err) {
        //    console.log('error createNewPlayerTeam') }
    }

    const fetchTeamPlayers = async (teamId) => {
        setIsTeamFormShown(current => "team page")
        const teamPlayersData = await API.graphql({
            query: teamPlayersByTeamId, 
            variables: {teamId: teamId}              
        })
        const teamPlayers = teamPlayersData.data.teamPlayersByTeamId.items
        //console.log(teamPlayers)
        setTeamPlayers(teamPlayers)
        setTeamName(current => teamPlayers[0].team.name)
        var teamTotal = 0
        //console.log(teamPlayers)

        teamPlayers.map((teamPlayer) => {
            teamPlayer.playerTotalEarnings = 0
            //console.log(teamPlayer.player)
            const playerResults = teamPlayer.player.results.items
            playerResults.map((result) => {
                //console.log(result.earnings)
                teamPlayer.playerTotalEarnings = teamPlayer.playerTotalEarnings + result.earnings
                //console.log(teamPlayers)
                teamTotal = teamTotal + result.earnings
            })        
        })
        setTeamTotal(current => teamTotal)
        //console.log(teamPlayers)
    }

    
    switch(isTeamFormShown) {
        case "b":
          return (
            <div className="home">
                <h2 className="center">TK Golf 2024</h2>
    {/*            
                <p className="center">TK Golf team submission coming Mid-March 2023</p>
                <p className="center">TK Golf season will begin with the Masters - April 6th, 2023</p>
    */}
                
                    <div className="app__input">
                        <button className="button1" onClick={openStandings}>Back</button>
                        <h4 className="center">Please create a new team:</h4>
                        <input
                            onChange={event => setInput('name', event.target.value)}
                            value={formState.name}
                            placeholder="Team Name"
                        />
                        <input
                            onChange={event => setInput('ownerName', event.target.value)}
                            value={formState.ownerName}
                            placeholder="Owner Name"
                        />
                        <input
                            onChange={event => setInput('email', event.target.value)}
                            value={formState.email}
                            placeholder="Email"
                        />
                        <select
                            onChange={event => {
                                setInput('player1', event.target.value);
                                //setInput('p1name', event.target.options[event.target.selectedIndex].text);
                                //p1Name = event.target.options[event.target.selectedIndex].text

                            }}
                            value={formState.player1}>

                            {tier1Players.map((option) => (
                                <option key={option?.id} value={option?.id}>{option?.name}</option>
                            ))}
                        </select>
                        <select 
                            onChange={event => setInput('player2', event.target.value)}
                            value={formState.player2}>

                            {tier2Players.map((option) => (
                                <option key={option?.id} value={option?.id}>{option?.name}</option>
                            ))}
                        </select>
                        <select 
                            onChange={event => setInput('player3', event.target.value)}
                            value={formState.player3}>

                            {tier3Players.map((option) => (
                                <option key={option?.id} value={option?.id}>{option?.name}</option>
                            ))}
                        </select>
                        <select 
                            onChange={event => setInput('player4', event.target.value)}
                            value={formState.player4}>

                            {tier3Players.map((option) => (
                                <option key={option?.id} value={option?.id}>{option?.name}</option>
                            ))}
                        </select>
                        <select 
                            onChange={event => setInput('player5', event.target.value)}
                            value={formState.player5}>

                            {tier3Players.map((option) => (
                                <option key={option?.id} value={option?.id}>{option?.name}</option>
                            ))}
                        </select>
                        <button 
                            className="submit-button" onClick={() => {
                                newFunction()                           
                            }
                        }>
                        Submit Team</button>               
                    </div>
            </div>
          )  
        case "a":
          return (
            <div className="home">
                <h2 className="center">TK Golf 2024</h2>
                    <div className="home__table">
                        <h4 className="center">Standings updated through the BMW Championship</h4>
                        {/*<button className="button1" onClick={openCreateTeam}>Create Team</button>*/}
                        <button className="button1" onClick={openSchedule}>Schedule</button>
                        <h4 className="center">Team Standings</h4>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell ><b>Team Name</b></TableCell>
                                        <TableCell ><b>Earnings</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                {/*AFTER SEASON STARTS*/} 
                                <TableBody>
                                    {teams
                                    .sort( (a,b) => a.teamTotal > b.teamTotal ? -1 : 1 )
                                    .map((row) => (
                                        <TableRow key={row?.id}>
                                            <TableCell component="th" scope="row" onClick={() => {fetchTeamPlayers(row?.id)}}>
                                                {row?.name}
                                            </TableCell>
                                            <TableCell >${row?.teamTotal.toLocaleString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                {/*DURING TEAM CREATION*/}
                                {/*
                                <TableBody>
                                    {teams.map((row) => (
                                        <TableRow key={row?.id}>
                                            <TableCell component="th" scope="row">
                                                {row?.name}
                                            </TableCell>
                                            <TableCell >$0</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                */}
                            </Table>
                        </TableContainer>
                    </div>
                
            </div>
          )
        case "team page":
          return (
            <div className="home">
                <h2 className="center">TK Golf 2024</h2>
                
                <div className="home__table">
                    <button className="button1" onClick={openStandings}>Back</button>
                    <h4 className="center">{teamName} </h4>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell ><b>Player Name</b></TableCell>
                                    <TableCell ><b>Earnings</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {teamPlayers
                                .sort( (a,b) => a.playerTotalEarnings > b.playerTotalEarnings ? -1 : 1 )
                                .map((row) => (
                                    <TableRow key={row?.id}>
                                        <TableCell component="th" scope="row">
                                            {row?.player.name}
                                        </TableCell>
                                        <TableCell >${row?.playerTotalEarnings.toLocaleString()}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell><b>Total Earnings</b></TableCell>
                                    <TableCell><b>${teamTotal.toLocaleString()}</b></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
          )
        case "schedule":
          return (
            <div className="home">
                <h2 className="center">TK Golf 2024</h2>
                
                <div className="home__table">
                    <button className="button1" onClick={openStandings}>Back</button>
                    <h4 className="center">Schedule </h4>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell ><b>Event</b></TableCell>
                                    <TableCell ><b>Dates</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Masters Tournament
                                    </TableCell>
                                    <TableCell >Apr 11 - 14</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        RBC Heritage
                                    </TableCell>
                                    <TableCell >Apr 18 - 21</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        CJ Cup Byron Nelson
                                    </TableCell>
                                    <TableCell >May 2 - 5</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Wells Fargo Championship
                                    </TableCell>
                                    <TableCell >May 9 - 12</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        PGA Championship
                                    </TableCell>
                                    <TableCell >May 16 - 19</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Charles Schwab Challenge
                                    </TableCell>
                                    <TableCell >May 23 - 26</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        RBC Canadian Open
                                    </TableCell>
                                    <TableCell >May 30 - Jun 2</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        The Memorial Tournament
                                    </TableCell>
                                    <TableCell >Jun 6 - 9</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        U.S. Open
                                    </TableCell>
                                    <TableCell >Jun 13 - 16</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Travelers Championship
                                    </TableCell>
                                    <TableCell >Jun 20 - 23</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Rocket Mortgage Classic
                                    </TableCell>
                                    <TableCell >Jun 27 - 30</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        John Deere Classic
                                    </TableCell>
                                    <TableCell >Jul 4 - 7</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Genesis Scottish Open
                                    </TableCell>
                                    <TableCell >Jul 11 - 14</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        The Open Championship
                                    </TableCell>
                                    <TableCell >Jul 18 - 21</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        3M Open
                                    </TableCell>
                                    <TableCell >Jul 25 - 28</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Wyndham Championship
                                    </TableCell>
                                    <TableCell >Aug 8 - 11</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        FedEx St. Jude Championship
                                    </TableCell>
                                    <TableCell >Aug 15 - 18</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        BMW Championship
                                    </TableCell>
                                    <TableCell >Aug 22 - 25</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
          )
    }   
}    

export default Home;
