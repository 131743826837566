import React from 'react';

import './App.css';
import Home from './Home'
//import Schedule from './Schedule'

function App() {

/*    const [isSchedule, setIsSchedule] = useState("");

    const openSchedule = async () => {
        setIsSchedule(current => "schedule")

    }

    const openHome = async () => {
        setIsSchedule(current => "home")

    }

    
    switch(isSchedule) {
      case "schedule":
        return (
          <div className="app">
            <button className="button1" onClick={openHome}>Home</button>
            <Schedule />
          </div>
        );
      case "home":
        return (
          <div className="app">
            <button className="button1" onClick={openHome}>Home</button>
            <Home />
          </div>
        );
    }*/

    return (
          <div className="app">
            <Home />
          </div>
        );
}

export default App;
