/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      ownerName
      email
      players {
        items {
          id
          teamId
          playerId
          team {
            id
            name
            ownerName
            email
            players {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          player {
            id
            name
            tier
            rank
            teams {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            results {
              items {
                id
                tournament
                earnings
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                playerResultsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ownerName
        email
        players {
          items {
            id
            teamId
            playerId
            team {
              id
              name
              ownerName
              email
              players {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlayer = /* GraphQL */ `
  query GetPlayer($id: ID!) {
    getPlayer(id: $id) {
      id
      name
      tier
      rank
      teams {
        items {
          id
          teamId
          playerId
          team {
            id
            name
            ownerName
            email
            players {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          player {
            id
            name
            tier
            rank
            teams {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            results {
              items {
                id
                tournament
                earnings
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                playerResultsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      results {
        items {
          id
          tournament
          earnings
          player {
            id
            name
            tier
            rank
            teams {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            results {
              items {
                id
                tournament
                earnings
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                playerResultsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          playerResultsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPlayers = /* GraphQL */ `
  query ListPlayers(
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        tier
        rank
        teams {
          items {
            id
            teamId
            playerId
            team {
              id
              name
              ownerName
              email
              players {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        results {
          items {
            id
            tournament
            earnings
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            playerResultsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const playerByTierAndRank = /* GraphQL */ `
  query PlayerByTierAndRank(
    $tier: Int!
    $rank: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPlayerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PlayerByTierAndRank(
      tier: $tier
      rank: $rank
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        tier
        rank
        teams {
          items {
            id
            teamId
            playerId
            team {
              id
              name
              ownerName
              email
              players {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        results {
          items {
            id
            tournament
            earnings
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            playerResultsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResult = /* GraphQL */ `
  query GetResult($id: ID!) {
    getResult(id: $id) {
      id
      tournament
      earnings
      player {
        id
        name
        tier
        rank
        teams {
          items {
            id
            teamId
            playerId
            team {
              id
              name
              ownerName
              email
              players {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        results {
          items {
            id
            tournament
            earnings
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            playerResultsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      playerResultsId
    }
  }
`;
export const listResults = /* GraphQL */ `
  query ListResults(
    $filter: ModelResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tournament
        earnings
        player {
          id
          name
          tier
          rank
          teams {
            items {
              id
              teamId
              playerId
              team {
                id
                name
                ownerName
                email
                players {
                  nextToken
                }
                createdAt
                updatedAt
              }
              player {
                id
                name
                tier
                rank
                teams {
                  nextToken
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          results {
            items {
              id
              tournament
              earnings
              player {
                id
                name
                tier
                rank
                teams {
                  nextToken
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              playerResultsId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        playerResultsId
      }
      nextToken
    }
  }
`;
export const getTeamsimple = /* GraphQL */ `
  query GetTeamsimple($id: ID!) {
    getTeamsimple(id: $id) {
      id
      name
      ownerName
      email
      player1
      player2
      player3
      player4
      player5
      createdAt
      updatedAt
    }
  }
`;
export const listTeamsimples = /* GraphQL */ `
  query ListTeamsimples(
    $filter: ModelTeamsimpleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamsimples(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        ownerName
        email
        player1
        player2
        player3
        player4
        player5
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeamPlayers = /* GraphQL */ `
  query GetTeamPlayers($id: ID!) {
    getTeamPlayers(id: $id) {
      id
      teamId
      playerId
      team {
        id
        name
        ownerName
        email
        players {
          items {
            id
            teamId
            playerId
            team {
              id
              name
              ownerName
              email
              players {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      player {
        id
        name
        tier
        rank
        teams {
          items {
            id
            teamId
            playerId
            team {
              id
              name
              ownerName
              email
              players {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        results {
          items {
            id
            tournament
            earnings
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            playerResultsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTeamPlayers = /* GraphQL */ `
  query ListTeamPlayers(
    $filter: ModelTeamPlayersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamPlayers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamId
        playerId
        team {
          id
          name
          ownerName
          email
          players {
            items {
              id
              teamId
              playerId
              team {
                id
                name
                ownerName
                email
                players {
                  nextToken
                }
                createdAt
                updatedAt
              }
              player {
                id
                name
                tier
                rank
                teams {
                  nextToken
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        player {
          id
          name
          tier
          rank
          teams {
            items {
              id
              teamId
              playerId
              team {
                id
                name
                ownerName
                email
                players {
                  nextToken
                }
                createdAt
                updatedAt
              }
              player {
                id
                name
                tier
                rank
                teams {
                  nextToken
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          results {
            items {
              id
              tournament
              earnings
              player {
                id
                name
                tier
                rank
                teams {
                  nextToken
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              playerResultsId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamPlayersByTeamId = /* GraphQL */ `
  query TeamPlayersByTeamId(
    $teamId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamPlayersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamPlayersByTeamId(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamId
        playerId
        team {
          id
          name
          ownerName
          email
          players {
            items {
              id
              teamId
              playerId
              team {
                id
                name
                ownerName
                email
                players {
                  nextToken
                }
                createdAt
                updatedAt
              }
              player {
                id
                name
                tier
                rank
                teams {
                  nextToken
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        player {
          id
          name
          tier
          rank
          teams {
            items {
              id
              teamId
              playerId
              team {
                id
                name
                ownerName
                email
                players {
                  nextToken
                }
                createdAt
                updatedAt
              }
              player {
                id
                name
                tier
                rank
                teams {
                  nextToken
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          results {
            items {
              id
              tournament
              earnings
              player {
                id
                name
                tier
                rank
                teams {
                  nextToken
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              playerResultsId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teamPlayersByPlayerId = /* GraphQL */ `
  query TeamPlayersByPlayerId(
    $playerId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamPlayersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teamPlayersByPlayerId(
      playerId: $playerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamId
        playerId
        team {
          id
          name
          ownerName
          email
          players {
            items {
              id
              teamId
              playerId
              team {
                id
                name
                ownerName
                email
                players {
                  nextToken
                }
                createdAt
                updatedAt
              }
              player {
                id
                name
                tier
                rank
                teams {
                  nextToken
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        player {
          id
          name
          tier
          rank
          teams {
            items {
              id
              teamId
              playerId
              team {
                id
                name
                ownerName
                email
                players {
                  nextToken
                }
                createdAt
                updatedAt
              }
              player {
                id
                name
                tier
                rank
                teams {
                  nextToken
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          results {
            items {
              id
              tournament
              earnings
              player {
                id
                name
                tier
                rank
                teams {
                  nextToken
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              playerResultsId
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
