/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      ownerName
      email
      players {
        items {
          id
          teamId
          playerId
          team {
            id
            name
            ownerName
            email
            players {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          player {
            id
            name
            tier
            rank
            teams {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            results {
              items {
                id
                tournament
                earnings
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                playerResultsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      ownerName
      email
      players {
        items {
          id
          teamId
          playerId
          team {
            id
            name
            ownerName
            email
            players {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          player {
            id
            name
            tier
            rank
            teams {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            results {
              items {
                id
                tournament
                earnings
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                playerResultsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      ownerName
      email
      players {
        items {
          id
          teamId
          playerId
          team {
            id
            name
            ownerName
            email
            players {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          player {
            id
            name
            tier
            rank
            teams {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            results {
              items {
                id
                tournament
                earnings
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                playerResultsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPlayer = /* GraphQL */ `
  mutation CreatePlayer(
    $input: CreatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    createPlayer(input: $input, condition: $condition) {
      id
      name
      tier
      rank
      teams {
        items {
          id
          teamId
          playerId
          team {
            id
            name
            ownerName
            email
            players {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          player {
            id
            name
            tier
            rank
            teams {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            results {
              items {
                id
                tournament
                earnings
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                playerResultsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      results {
        items {
          id
          tournament
          earnings
          player {
            id
            name
            tier
            rank
            teams {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            results {
              items {
                id
                tournament
                earnings
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                playerResultsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          playerResultsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePlayer = /* GraphQL */ `
  mutation UpdatePlayer(
    $input: UpdatePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    updatePlayer(input: $input, condition: $condition) {
      id
      name
      tier
      rank
      teams {
        items {
          id
          teamId
          playerId
          team {
            id
            name
            ownerName
            email
            players {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          player {
            id
            name
            tier
            rank
            teams {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            results {
              items {
                id
                tournament
                earnings
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                playerResultsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      results {
        items {
          id
          tournament
          earnings
          player {
            id
            name
            tier
            rank
            teams {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            results {
              items {
                id
                tournament
                earnings
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                playerResultsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          playerResultsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePlayer = /* GraphQL */ `
  mutation DeletePlayer(
    $input: DeletePlayerInput!
    $condition: ModelPlayerConditionInput
  ) {
    deletePlayer(input: $input, condition: $condition) {
      id
      name
      tier
      rank
      teams {
        items {
          id
          teamId
          playerId
          team {
            id
            name
            ownerName
            email
            players {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          player {
            id
            name
            tier
            rank
            teams {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            results {
              items {
                id
                tournament
                earnings
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                playerResultsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      results {
        items {
          id
          tournament
          earnings
          player {
            id
            name
            tier
            rank
            teams {
              items {
                id
                teamId
                playerId
                team {
                  id
                  name
                  ownerName
                  email
                  createdAt
                  updatedAt
                }
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            results {
              items {
                id
                tournament
                earnings
                player {
                  id
                  name
                  tier
                  rank
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
                playerResultsId
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          playerResultsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createResult = /* GraphQL */ `
  mutation CreateResult(
    $input: CreateResultInput!
    $condition: ModelResultConditionInput
  ) {
    createResult(input: $input, condition: $condition) {
      id
      tournament
      earnings
      player {
        id
        name
        tier
        rank
        teams {
          items {
            id
            teamId
            playerId
            team {
              id
              name
              ownerName
              email
              players {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        results {
          items {
            id
            tournament
            earnings
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            playerResultsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      playerResultsId
    }
  }
`;
export const updateResult = /* GraphQL */ `
  mutation UpdateResult(
    $input: UpdateResultInput!
    $condition: ModelResultConditionInput
  ) {
    updateResult(input: $input, condition: $condition) {
      id
      tournament
      earnings
      player {
        id
        name
        tier
        rank
        teams {
          items {
            id
            teamId
            playerId
            team {
              id
              name
              ownerName
              email
              players {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        results {
          items {
            id
            tournament
            earnings
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            playerResultsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      playerResultsId
    }
  }
`;
export const deleteResult = /* GraphQL */ `
  mutation DeleteResult(
    $input: DeleteResultInput!
    $condition: ModelResultConditionInput
  ) {
    deleteResult(input: $input, condition: $condition) {
      id
      tournament
      earnings
      player {
        id
        name
        tier
        rank
        teams {
          items {
            id
            teamId
            playerId
            team {
              id
              name
              ownerName
              email
              players {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        results {
          items {
            id
            tournament
            earnings
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            playerResultsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      playerResultsId
    }
  }
`;
export const createTeamsimple = /* GraphQL */ `
  mutation CreateTeamsimple(
    $input: CreateTeamsimpleInput!
    $condition: ModelTeamsimpleConditionInput
  ) {
    createTeamsimple(input: $input, condition: $condition) {
      id
      name
      ownerName
      email
      player1
      player2
      player3
      player4
      player5
      createdAt
      updatedAt
    }
  }
`;
export const updateTeamsimple = /* GraphQL */ `
  mutation UpdateTeamsimple(
    $input: UpdateTeamsimpleInput!
    $condition: ModelTeamsimpleConditionInput
  ) {
    updateTeamsimple(input: $input, condition: $condition) {
      id
      name
      ownerName
      email
      player1
      player2
      player3
      player4
      player5
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeamsimple = /* GraphQL */ `
  mutation DeleteTeamsimple(
    $input: DeleteTeamsimpleInput!
    $condition: ModelTeamsimpleConditionInput
  ) {
    deleteTeamsimple(input: $input, condition: $condition) {
      id
      name
      ownerName
      email
      player1
      player2
      player3
      player4
      player5
      createdAt
      updatedAt
    }
  }
`;
export const createTeamPlayers = /* GraphQL */ `
  mutation CreateTeamPlayers(
    $input: CreateTeamPlayersInput!
    $condition: ModelTeamPlayersConditionInput
  ) {
    createTeamPlayers(input: $input, condition: $condition) {
      id
      teamId
      playerId
      team {
        id
        name
        ownerName
        email
        players {
          items {
            id
            teamId
            playerId
            team {
              id
              name
              ownerName
              email
              players {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      player {
        id
        name
        tier
        rank
        teams {
          items {
            id
            teamId
            playerId
            team {
              id
              name
              ownerName
              email
              players {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        results {
          items {
            id
            tournament
            earnings
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            playerResultsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTeamPlayers = /* GraphQL */ `
  mutation UpdateTeamPlayers(
    $input: UpdateTeamPlayersInput!
    $condition: ModelTeamPlayersConditionInput
  ) {
    updateTeamPlayers(input: $input, condition: $condition) {
      id
      teamId
      playerId
      team {
        id
        name
        ownerName
        email
        players {
          items {
            id
            teamId
            playerId
            team {
              id
              name
              ownerName
              email
              players {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      player {
        id
        name
        tier
        rank
        teams {
          items {
            id
            teamId
            playerId
            team {
              id
              name
              ownerName
              email
              players {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        results {
          items {
            id
            tournament
            earnings
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            playerResultsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeamPlayers = /* GraphQL */ `
  mutation DeleteTeamPlayers(
    $input: DeleteTeamPlayersInput!
    $condition: ModelTeamPlayersConditionInput
  ) {
    deleteTeamPlayers(input: $input, condition: $condition) {
      id
      teamId
      playerId
      team {
        id
        name
        ownerName
        email
        players {
          items {
            id
            teamId
            playerId
            team {
              id
              name
              ownerName
              email
              players {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      player {
        id
        name
        tier
        rank
        teams {
          items {
            id
            teamId
            playerId
            team {
              id
              name
              ownerName
              email
              players {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        results {
          items {
            id
            tournament
            earnings
            player {
              id
              name
              tier
              rank
              teams {
                items {
                  id
                  teamId
                  playerId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              results {
                items {
                  id
                  tournament
                  earnings
                  createdAt
                  updatedAt
                  playerResultsId
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
            playerResultsId
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
